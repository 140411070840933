import React from "react";

const HeroSection = ({ title = "", backgroundImage }) => {
  const style = backgroundImage
    ? { backgroundImage: `url(${backgroundImage})` }
    : {};

  return (
    <section className="residence-banner relative" style={style}>
      <div className="d-flex align-items-center abs-full tint">
        <div className="container">
          <div className="d-flex justify-content-center align-items-center">
            <div className="text-center">
              <h2 className="banner-title">{title}</h2>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
